import React from 'react';
import './footer.css'
import { Link } from 'react-router-dom';

export default function Footer (){

  return(

 
<footer className="footer">
  <div className="footer__addr">
    <h1 className="footer__logo">FFTI Solutions</h1>
        
    <h2>Contact</h2>
    <p>9143730730</p>
    <address style={{color:"white"}}>
    FFTI solutions,
    Srinagar colony,yellaredyguda,Hyderabad, Telangana
    </address>
  </div>
  
  <ul className="footer__nav">  
    <li className="nav__item nav__item--extra">
      <h2 className="nav__title">Stores</h2>
      
      <ul className="nav__ul nav__ul--extra">
        <li>
        <Link to="/">Home</Link>
        </li>
        
        <li>
        <Link to="/about-us">About</Link>
        </li>
        
        <li>
        <Link to="/services">services</Link>
        </li>
        
        <li>
        <Link to="/contact-us">contact us</Link>
        </li>
      </ul>
    </li>

    <li className="nav__item mt-3">
     
     <h3 className="nav__title">Services Provided</h3>
     <ul className="nav__ul">
       <li style={{textDecoration:"underline",fontStyle:"italic"}}>
         <a href="">Web development</a>
       </li>
       
       <li style={{textDecoration:"underline",fontStyle:"italic"}}>
         <a href="">Digital Marketing </a>
       </li>
       <li style={{textDecoration:"underline",fontStyle:"italic"}}>
         <a href="">SEO</a>
       </li>
       
       <li style={{textDecoration:"underline",fontStyle:"italic"}}>
         <a href="">Social Media Marketing</a>
       </li>
       <li style={{textDecoration:"underline",fontStyle:"italic"}}>
         <a href="">E-commerce</a>
       </li>
       
       <li style={{textDecoration:"underline",fontStyle:"italic"}}>
         <a href="">Corporate Website</a>
       </li>
     </ul>
   </li>
    
  </ul>
  
  <div className="legal">
    <p>&copy; 2021. All rights reserved.</p>
  </div>
</footer>

  )

}
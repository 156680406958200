import React from "react";
import "./about.css"

const About=()=>{
    return(
        <div className="aboutmaincontainer">
            <img src="https://www.influxu.com/new%20images/index/about.jpg" alt="office_img"/>
            <div>
                <div>
                    <h2>Company Info</h2>
                    <p>Established in 2021 FFTI solutions service thriving software industry and 
                        with 14 years of industry experience professionals to service its clients full-life-cycle applications 
                        and provides cost-effective, innovative IT consulting services and solutions to 
                        clients across multiple industries. Throughout our journey, we have been widely recognized 
                        for our collaborative, client-centric approach to the business experience, as well as our passion for excellence, 
                        both of which have resulted in significant year-on-year growth.</p>
                </div>
                <div>
                    <h2>Mission</h2>
                    <p>Our mission is to enhance growth of our Customers and Employees with cutting edge technologies and creative  
                        solutions.<br/>Our work is built on four pillars, we strive hard to maintain balance of all the pillars</p>
                    <ul>
                        <li>Serve Industry Esteem Customers</li>
                        <li>Partner with Client and Support Client Requirements</li>
                        <li>Dynamic and Adaptive to any Customer</li>
                        <li>Grow Efficient Workforce Team</li>
                    </ul>
                </div>
                
            </div>
        </div>
    )
}

export default About;
import Carousel from 'react-bootstrap/Carousel';
import "./Carousel.css"

function Car() {
  return (
    <Carousel data-bs-theme="dark">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://www.influxu.com/new%20images/index/slide1.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h5>Global Leader In It Solutions</h5>
          <p className='para'>At Futures First Tech India Solutions we tailor our services across the sectors</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://www.influxu.com/new%20images/index/slide3.jpg"
          alt="Second slide"
        />
        <Carousel.Caption>
          <h5>IT PROJECT SOLUTOIONS</h5>
          <p className='para'>Collaborate with clients, understand their it application requirements and provide a custom solution</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://t3.ftcdn.net/jpg/04/57/93/78/360_F_457937822_6guyVRMr4cCdCr36zPg7Er7WRaf0FbSn.jpg"
          alt="Third slide"
        />
        <Carousel.Caption>
          <h5>Business process Outsourcing</h5>
          <p className='para'>
            we having industry expereince and grown exponentially servedvarious sector clients 
            providing custome business operations solution
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Car;
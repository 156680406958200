import React from "react";
import Car from "./Carousel";
import "./home.css";

const Home = () => {
    return(
        <>
        <Car/>
        
            <div className="firstcontainer">
                <p>
                Futures First Tech India solutions provides cost-effective, innovative IT consulting services and solutions to clients across multiple industries. 
                Throughout our journey, we have been widely recognized for our collaborative, client-centric approach to the business experience, 
                as well as our passion for excellence, both of which have resulted in significant year-on-year growth. Over the years, FFTI has greatly expanded the range of our services and solutions products. 
                Using advanced, up-to-date technologies and methodologies, we span a broad spectrum of Information Technology domains, including Application Development and Maintenance, Mobility Solutions, Production Support, Quality Assurance and Testing, 
                Program Governance, Staff Augmentation.
                </p>
                <img src="https://www.csrkinfotech.com/_next/image?url=%2Fpromotion.png&w=2048&q=75" alt=""/>
            </div>
            <div className="secondcontainer">
                <img src="https://www.csrkinfotech.com/_next/image?url=%2Fabout.png&w=640&q=75" alt=""/>
                <p>
                Futures First Tech India Solutions is a dynamic and innovative company that specializes in providing a 
                comprehensive suite of digital services. 
                With a focus on web development, SEO, digital marketing, social media marketing, and influencer marketing, InnovateWeb Solutions is dedicated to helping businesses thrive in the ever-evolving digital landscape.
                </p>

            </div>
      
        </>
        
    )
}

export default Home;
import React from "react"
import Navbar from "./navbar";
import Home from "./home";
import About from "./about";
import Services from "./services";
import Contact from "./contact";
import { BrowserRouter, Route, Routes} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "./footer";

const App=()=>{


  return (
    <div>
      <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route exact path="/" element={<Home/>}></Route>
        <Route exact path="/about-us" element={<About/>}></Route>
        <Route exact path="/services" element={<Services/>}></Route>
        <Route exact path="/contact-us" element={<Contact/>}></Route>
      </Routes>
      <Footer/>
      </BrowserRouter>
      
      
    </div>
  );
}

export default App;

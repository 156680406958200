import React from "react";
import "./contact.css"

const Contact=()=>{
    return(
        <div className="contactmaincontainer">
            <div className="leftcontainer">
                <div className="textcontainer">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="rounded-circle bg-info text-white p-1" height="35" width="35" xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" d="M0 0h24v24H0z"></path><path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56a.977.977 0 00-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"></path>
                </svg>
                <p><span>Phone</span><br/>9143730730</p>
                </div>

                <div className="textcontainer">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="rounded-circle bg-info text-white p-1" height="35" width="35" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path></svg>
                <p><span>E-mail</span><br/>fftisolutions@gmail.com</p>
                </div>

                <div className="textcontainer">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="rounded-circle bg-info text-white p-1" height="35" width="35" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 1.74.5 3.37 1.41 4.84.95 1.54 2.2 2.86 3.16 4.4.47.75.81 1.45 1.17 2.26.26.55.47 1.5 1.26 1.5s1-.95 1.25-1.5c.37-.81.7-1.51 1.17-2.26.96-1.53 2.21-2.85 3.16-4.4C18.5 12.37 19 10.74 19 9c0-3.87-3.13-7-7-7zm0 9.75a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path></svg>
                <p><span>Location</span><br/>srinagar colony,yellaredyguda,hyderabad</p>
                </div>

            </div>

            <div className="map-container">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.5656403199187!2d78.43544587390707!3d17.43262065150296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb90d10b0f93fd%3A0x63192c22c87b682b!2sSrinagar%20Colony%2C%20Imam%20Guda%2C%20Yousufguda%2C%20Hyderabad%2C%20Telangana%20500045!5e0!3m2!1sen!2sin!4v1708364361865!5m2!1sen!2sin" 
                className="fct-map" allowfullscreen="" loading="lazy" title="officelocation" ></iframe>
            </div>
        </div>

       
     
        
          
            
    )
}

export default Contact
import React from "react"
import "./navbar.css"
import { Link } from "react-router-dom";
import logo from "./ffti.PNG" 



const Navbar=()=>{
    return(
        <div >
            <div className="navbarmain">
            <div className="navbarfirst">
                <p>
                Hyderabad, India | fftisolutions@gmail.com
                </p>
                <p>
                    9143730730 | Office Hours: 7 PM -4 AM IST
                </p>
            </div>
            </div>
            
            <div className="navbarsecond">
                <img src={logo} alt="fftilogo"/>
                <div className="navbarlinkscontainer">
                    <ul>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/about-us">About us</Link>
                        </li>
                        <li>
                            <Link to="/services">Services</Link>
                            </li>
                        <li>
                            <Link to="/contact-us">Contact us</Link>
                        </li>
                    </ul>
                </div>
                  
            </div>
            
        </div>
    )
}
export default Navbar
import React from "react";
import "./services.css"

const Services=()=>{
    return(
        <div className="smain"> 
            <div className="scard">
                <img src="https://www.csrkinfotech.com/domains/web-development.jpg" alt="card-img"/>
                <div className="scardtext">
                    <h3>Web Development</h3>
                    <p>Creating, building, Hosting and maintaining websites and
                        web apllications that run online on a browser
                    </p>
                </div>
            </div>
            <div className="scard">
                <img src="https://www.csrkinfotech.com/domains/digital-marketing.jpg" alt="card-img"/>
                <div className="scardtext">
                    <h3>Digital Marketing</h3>
                    <p>Digital services refer to a wide range of online
                        offerings provided by businesses or individuals, and more
                    </p>
                </div>
            </div>
            <div className="scard">
                <img src="https://www.csrkinfotech.com/domains/seo.jpg" alt="card-img"/>
                <div className="scardtext">
                    <h3>SEO</h3>
                    <p>From keyword optimization to content strategy, discover how we implement
                        SEO best practices to enchance your online presence
                    </p>
                </div>
            </div>

            <div className="scard">
                <img src="https://www.csrkinfotech.com/domains/social-media-marketing.jpg" alt="card-img"/>
                <div className="scardtext">
                    <h3>Social Media Marketing</h3>
                    <p>social media marketing servicesthe power of platforms
                        to create compelling campigns and foster meaningful connections
                    </p>
                </div>
            </div>
            <div className="scard">
                <img src="https://www.csrkinfotech.com/domains/corporate-website.jpg" alt="card-img"/>
                <div className="scardtext">
                    <h3>Corporate Webite</h3>
                    <p>creating sophisticated,informative,and user-friendly websites that reflect
                        the essence of your brand
                    </p>
                </div>
            </div>
            <div className="scard">
                <img src="https://www.csrkinfotech.com/domains/e-commerce.jpg" alt="card-img"/>
                <div className="scardtext">
                    <h3>E-Commerce</h3>
                    <p>e-commerce web development services blend functionality with
                        aesthetics, offering a personalized and secure space for your customers to shop
                    </p>
                </div>
            </div>

        </div>
    )
}
export default Services